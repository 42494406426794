import { DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Col,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tabs,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./Workers.module.css";
import {
  deleteCaseyJob,
  getCaseyWorkers,
  getFailedWorkers,
  getWorkers,
  workersSelector,
} from "./workersSlice";

export function Workers() {
  const { data, failed, errors } = useSelector(workersSelector);
  const dispatch = useDispatch();
  const [queue, setQueue] = useState();
  const [infoOpened, setInfoOpened] = useState(false);
  const [traceOpened, setTraceOpened] = useState(false);
  const [caseyWorkers, setCaseyWorkers] = useState([]);

  useEffect(() => {
    dispatch(getWorkers());
  }, [dispatch]);

  useEffect(() => {
    refreshCaseyWorkers();
  }, [dispatch]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  const openInfoModal = (queue) => {
    setInfoOpened(true);
    dispatch(getFailedWorkers({ queue: queue }));
  };

  const openTraceModal = (id, trace) => {
    Modal.error({
      width: 1000,
      closable: true,
      maskClosable: true,
      title: id,
      style: { top: "64px" },
      footer: null,
      content: (
        <div>
          <pre style={{ maxWidth: "1000px", overflowX: "auto" }}>{trace}</pre>
        </div>
      ),
    });
  };

  const refreshCaseyWorkers = () => {
    dispatch(getCaseyWorkers()).then((res) => {
      if (res.type === "workers/casey/index/fulfilled") {
        setCaseyWorkers(res.payload);
      } else {
        message.error("Failed to fetch casey workers");
      }
    });
  };

  const deleteJob = (worker, jobId) => {
    dispatch(deleteCaseyJob({ worker: worker, jobId: jobId })).then((res) => {
      if (res.type === "workers/casey/deleteJob/fulfilled") {
        message.success("Job deleted successfully");
        refreshCaseyWorkers();
      } else {
        message.error("Failed to delete job");
      }
    });
  };

  const renderJobs = (worker) => {
    let tables = [];
    if (caseyWorkers[worker]) {
      tables = [
        ...Object.keys(caseyWorkers[worker]).map((w) => {
          let columns = [
            {
              title: "ID",
              dataIndex: "id",
              key: "id",
            },
            {
              title: "Data",
              dataIndex: "data",
              key: "data",
              render: (d) => <>{JSON.stringify(d)}</>,
            },
            {
              title: "Created At",
              dataIndex: "timestamp",
              key: "timestamp",
              render: (c) => <>{new Date(c).toLocaleString()}</>,
            },
            {
              title: "Attempts",
              dataIndex: "attemptsMade",
              key: "attemptsMade",
            },
          ];
          if (w === "delayed") {
            columns.push({
              title: "Delayed (ms)",
              dataIndex: "delay",
              key: "delay",
            });
          }
          if (w === "failed" || w === "completed") {
            columns.push({
              title: "Finished At",
              dataIndex: "finishedOn",
              key: "finishedOn",
              render: (c) => <>{new Date(c).toLocaleString()}</>,
            });
          }
          if (w === "failed" || w === "delayed") {
            columns.push({
              title: "Failed Reason",
              dataIndex: "failedReason",
              key: "failedReason",
            });
          }
          if (w === "failed") {
            columns.push({
              title: "Trace",
              dataIndex: "stacktrace",
              key: "stacktrace",
              render: (t, record) => (
                <Button
                  type="link"
                  onClick={() => openTraceModal(record.id, t)}
                >
                  View
                </Button>
              ),
            });
          }
          if (w !== "completed") {
            columns.push({
              key: "action",
              render: (record) => (
                <Popconfirm
                  title="Delete the task"
                  description="Are you sure to delete this job?"
                  onConfirm={() => deleteJob(worker, record.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" danger>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              ),
            });
          }
          return (
            <div className="mb-30" key={w}>
              <div className="fs-4 fw-500 mb-10">
                {w.charAt(0).toUpperCase() + w.slice(1)}
              </div>
              <Table
                bordered
                size="small"
                rowHoverable={false}
                columns={columns}
                dataSource={caseyWorkers[worker][w].map((w, i) => {
                  return { ...w, ...{ key: i } };
                })}
                pagination={false}
              />
            </div>
          );
        }),
      ];
    }
    return <>{tables}</>;
  };

  return (
    <>
      <div className="container">
        <div className="mb-15">
          <Row justify="space-between" wrap>
            <Col>
              <span className="fs-5 fw-300">Workers</span>
            </Col>
          </Row>
        </div>
        <div
          className="container bg-white b-shadow "
          style={{ paddingTop: "10px" }}
        >
          <Tabs
            defaultActiveKey="1"
            // centered
            size="large"
            items={[
              {
                label: `Casey Workers`,
                key: 1,
                children: (
                  <div className="mt-25">
                    <Tabs
                      defaultActiveKey="1"
                      centered
                      type="card"
                      items={[
                        {
                          label: (
                            <Space>
                              <div>Summary Jobs</div>
                              {caseyWorkers["summary"]?.failed.length ? (
                                <Badge
                                  count={caseyWorkers["summary"]?.failed.length}
                                  color="#faad14"
                                />
                              ) : (
                                ""
                              )}
                            </Space>
                          ),
                          key: 1,
                          children: (
                            <div className="mt-20">{renderJobs("summary")}</div>
                          ),
                        },
                        {
                          label: (
                            <Space>
                              <div>Announcement Jobs</div>
                              {caseyWorkers["announcement"]?.failed.length ? (
                                <Badge
                                  count={
                                    caseyWorkers["announcement"]?.failed.length
                                  }
                                  color="#faad14"
                                />
                              ) : (
                                ""
                              )}
                            </Space>
                          ),
                          key: 2,
                          children: (
                            <div className="mt-20">
                              {renderJobs("announcement")}
                            </div>
                          ),
                        },
                        {
                          label: (
                            <Space>
                              <div>Sentiment Jobs</div>
                              {caseyWorkers["sentiment"]?.failed.length ? (
                                <Badge
                                  count={
                                    caseyWorkers["sentiment"]?.failed.length
                                  }
                                  color="#faad14"
                                />
                              ) : (
                                ""
                              )}
                            </Space>
                          ),
                          key: 3,
                          children: (
                            <div className="mt-20">
                              {renderJobs("sentiment")}
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>
                ),
              },
              {
                label: `Other Workers`,
                key: 2,
                children: (
                  <div className="mt-30">
                    <Row gutter={24} align="middle">
                      <Col span={4} className="ta-c">
                        <span className="fs-6 fw-400">Hayagriva</span>
                      </Col>
                      <Col span={8}>
                        <div className={styles.box}>
                          <div className={styles.title}>
                            processing-pending&nbsp;&nbsp;
                            {data.data_source_pending_c > 0 && (
                              <span
                                className={styles.info}
                                onClick={() => {
                                  setQueue("processing-pending");
                                  openInfoModal("processing-pending");
                                }}
                              >
                                <WarningOutlined />{" "}
                              </span>
                            )}
                          </div>
                          <div className={styles.sub}>(Processing Pending)</div>
                          <div className={styles.val}>
                            {data.data_source_pending_c}
                          </div>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className={styles.box}>
                          <div className={styles.title}>
                            processing-failed&nbsp;&nbsp;
                            {data.data_source_failed_c > 0 && (
                              <span
                                className={styles.info}
                                onClick={() => {
                                  setQueue("processing-failed");
                                  openInfoModal("processing-failed");
                                }}
                              >
                                <WarningOutlined />{" "}
                              </span>
                            )}
                          </div>
                          <div className={styles.sub}>(Processing Failed)</div>
                          <div className={styles.val}>
                            {data.data_source_failed_c}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={24} align="middle" className="mt-25">
                      <Col span={4} className="ta-c">
                        <span className="fs-6 fw-400">Triton</span>
                      </Col>
                      <Col span={5}>
                        <div className={styles.box}>
                          <div className={styles.title}>donations-queue</div>
                          <div className={styles.sub}>
                            (Currently Processing)
                          </div>
                          <div className={styles.val}>{data.donations_c}</div>
                        </div>
                      </Col>
                      <Col span={5}>
                        <div className={styles.box}>
                          <div className={styles.title}>
                            donations-failed&nbsp;&nbsp;
                            {data.donations_failed_c > 0 && (
                              <span
                                className={styles.info}
                                onClick={() => {
                                  setQueue("donations-failed");
                                  openInfoModal("donations-failed");
                                }}
                              >
                                <WarningOutlined />{" "}
                              </span>
                            )}
                          </div>
                          <div className={styles.sub}>(Failed Processing)</div>
                          <div className={styles.val}>
                            {data.donations_failed_c}
                          </div>
                        </div>
                      </Col>
                      <Col span={5}>
                        <div className={styles.box}>
                          <div className={styles.title}>activity-queue</div>
                          <div className={styles.sub}>
                            (Currently Processing)
                          </div>
                          <div className={styles.val}>{data.activity_c}</div>
                        </div>
                      </Col>
                      <Col span={5}>
                        <div className={styles.box}>
                          <div className={styles.title}>
                            activity-failed&nbsp;&nbsp;
                            {data.activity_failed_c > 0 && (
                              <span
                                className={styles.info}
                                onClick={() => {
                                  setQueue("activity-failed");
                                  openInfoModal("activity-failed");
                                }}
                              >
                                <WarningOutlined />{" "}
                              </span>
                            )}
                          </div>
                          <div className={styles.sub}>(Failed Processing)</div>
                          <div className={styles.val}>
                            {data.activity_failed_c}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
      <Modal
        open={infoOpened}
        maskClosable
        width={750}
        title={queue}
        onCancel={() => setInfoOpened(false)}
        footer={null}
      >
        {queue === "processing-pending" || queue === "processing-failed" ? (
          <Table
            size="small"
            dataSource={failed}
            pagination={false}
            columns={[
              {
                title: "ID",
                dataIndex: "id",
                key: "id",
              },
              {
                title: "Type",
                dataIndex: "sourceable_type",
                key: "sourceable_type",
              },
              {
                title: "ID",
                dataIndex: "sourceable_id",
                key: "sourceable_id",
                render: (id, record) => (
                  <Link
                    to={`${
                      record.sourceable_type === "User"
                        ? `/users/${id}`
                        : `/nodes/${id}`
                    }`}
                  >
                    {id}
                  </Link>
                ),
              },
              {
                title: "Name",
                dataIndex: "name",
                key: "name",
                render: (text, record) => (
                  <a href={record.url} target="_blank" rel="noreferrer">
                    {text}
                  </a>
                ),
              },
              {
                title: "Created At",
                dataIndex: "created_at",
                key: "created_at",
                render: (text) => (
                  <div size="middle">
                    <div>{new Date(text).toLocaleString()}</div>
                  </div>
                ),
              },
            ]}
          />
        ) : (
          <div className={styles.logBox}>
            {failed.map((f) => (
              <div className="mb-15">{f}</div>
            ))}
          </div>
        )}
      </Modal>
    </>
  );
}
