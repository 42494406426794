import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  all: {
    data: [],
    loading: false,
    errors: null,
    totalPages: 0,
    totalCount: 0,
  },
  benefitCalculators: {
    data: [],
  },
};

export const searchServices = createAsyncThunk(
  "services/search",
  async (data, thunk) => {
    try {
      let response = await API.discover.searchServices(
        data.query,
        data.filters,
        data.page,
        data.per
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const searchServiceIds = createAsyncThunk(
  "services/searchIds",
  async (data, thunk) => {
    try {
      let response = await API.discover.searchServices(
        data.query,
        data.filters,
        data.page,
        data.per,
        "services_id"
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const searchGeography = createAsyncThunk(
  "services/searchGeography",
  async (data, thunk) => {
    try {
      let response = await API.discover.searchGeography(data.query, data.scope);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getService = createAsyncThunk(
  "services/show",
  async (data, thunk) => {
    try {
      let response = await API.services.show(data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const createService = createAsyncThunk(
  "services/create",
  async (data, thunk) => {
    try {
      let response = await API.services.create(data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateService = createAsyncThunk(
  "services/update",
  async (data, thunk) => {
    try {
      let response = await API.services.update(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateBulkService = createAsyncThunk(
  "services/updateBulk",
  async (data, thunk) => {
    try {
      let response = await API.services.updateBulk(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const deleteService = createAsyncThunk(
  "services/delete",
  async (data, thunk) => {
    try {
      let response = await API.services.delete(data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const listBenefitCalculators = createAsyncThunk(
  "services/listBenefitCalculators",
  async (data, thunk) => {
    try {
      let response = await API.services.listBenefitCalculators();
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getServiceHistory = createAsyncThunk(
  "services/getServiceHistory",
  async (data, thunk) => {
    try {
      let response = await API.services.getHistory(
        data.id,
        data.page,
        data.per
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [searchServices.pending]: (state) => {
      state.all.loading = true;
    },
    [searchServices.fulfilled]: (state, action) => {
      state.all.loading = false;
      state.all.data = action.payload.results;
      state.all.totalPages = action.payload.total_pages;
      state.all.totalCount = action.payload.total_count;
    },
    [searchServices.rejected]: (state, action) => {
      state.all.loading = false;
      state.all.data = [];
      state.all.totalPages = 0;
      state.all.totalCount = 0;
      state.all.errors = ["Invalid search query"];
    },
    [listBenefitCalculators.fulfilled]: (state, action) => {
      state.benefitCalculators.data = action.payload.benefit_calculators;
    },
  },
});

export const { clearErrors } = servicesSlice.actions;

export const allServicesSelector = (state) => state.services.all;
export const benefitCalculatorsSelector = (state) =>
  state.services.benefitCalculators;

export default servicesSlice.reducer;
