import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tabs,
  Typography,
  message,
} from "antd";

import { DatePicker, Select, Tag } from "antd";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Logo } from "../../components/logo/Logo";
import { deleteConversation } from "../conversations/conversationsSlice";
import { Conversations } from "../nodes/tabs/conversations/Conversations";
import { Data } from "../nodes/tabs/data/Data";
import { updateTerminationEmployee } from "../orgs/tabs/employees/employeesSlice";
import {
  currentUserSelector,
  forceSummary,
  getLatestConversation,
  getUser,
  resetProfile,
  resetSummary,
  updateUser,
} from "./usersSlice";

const { Paragraph } = Typography;
dayjs.extend(utc);
dayjs.extend(timezone);

export function UserDetail(props) {
  const dispatch = useDispatch();
  const [exitForm] = Form.useForm();
  const [caseyEndForm] = Form.useForm();
  const { data, loading, errors } = useSelector(currentUserSelector);
  const [user, setUser] = useState(null);
  const [latestConversation, setLatestConversation] = useState(null);
  const [lastSummary, setLastSummary] = useState(null);
  const [currentEmployer, setCurrentEmployer] = useState(null);
  const [exitInterviewOpened, setExitInterviewOpened] = useState(false);
  const [caseyEndOpened, setCaseyEndOpened] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    dispatch(
      getUser({
        id: props.id,
      })
    );
    dispatch(
      getLatestConversation({
        id: props.id,
      })
    ).then((res) => {
      if (res.type === "users/latestConversation/fulfilled") {
        setLatestConversation(res.payload?.conversation);
        setLastSummary(res.payload?.last_summary_conversation);
      }
    });

    return () => {
      setUser(null);
    };
  }, [props.id]);

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data]);

  const update = (values) => {
    if (values.casey_access_end_time) {
      const date = dayjs(values.casey_access_end_time).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      values.casey_access_end_time = dayjs
        .tz(date, "America/Los_Angeles")
        .toISOString();
    }

    dispatch(
      updateUser({
        id: user.id,
        data: values,
      })
    ).then((res) => {
      if (res.type === "users/update/fulfilled") {
        dispatch(
          getUser({
            id: props.id,
          })
        );
        setCaseyEndOpened(false);
      } else {
        message.error("Failed to update exit interview");
      }
    });
  };

  const updateExitInterview = (values) => {
    dispatch(
      updateTerminationEmployee({
        orgId: currentEmployer.id,
        data: { user_id: user.id, interview: values.interview },
      })
    ).then((res) => {
      if (res.type === "updateTerminationEmployee/fulfilled") {
        dispatch(
          getUser({
            id: props.id,
          })
        );
        closeExitInterviewModal();
      } else {
        message.error("Failed to update exit interview");
      }
    });
  };

  const closeExitInterviewModal = () => {
    setCurrentEmployer(null);
    setExitInterviewOpened(false);
  };

  const formatPhoneNumber = (phoneNumberString) =>
    phoneNumberString && phoneNumberString.length > 0
      ? phoneNumberString
          .replace(/(?<!^)\D/g, "")
          .replace(/^\+(\d{1})(\d{3})(\d{3})(\d{4})$/, "+$1 ($2) $3-$4")
      : "Browser Chat";

  return (
    <>
      {user?.id && (
        <div className="container">
          <div className="mb-25 bg-white b-shadow container pb-20">
            <Spin spinning={loading} style={{ padding: 20 }}>
              <Row gutter="24" align="top" wrap>
                <Col span="4" className="ta-c">
                  <Logo
                    type="user"
                    size="xxxl"
                    data={{ name: user?.f_name, src: user?.avatar }}
                  />
                </Col>
                <Col span="10">
                  <div className="fs-6 mb-15">
                    {user?.f_name}&nbsp;{user?.l_name}
                  </div>
                  <Row>
                    <Col span={6}>
                      <b>User ID:</b>
                    </Col>
                    <Col span={18}>{user?.id}</Col>
                  </Row>
                  <Row className="mt-10">
                    <Col span={6}>
                      <b>Phone:</b>
                    </Col>
                    <Col span={18}>{formatPhoneNumber(user?.phone)}</Col>
                  </Row>
                  <Row className="mt-10">
                    <Col span={6}>
                      <b>Location:</b>
                    </Col>
                    <Col span={18}>
                      {JSON.stringify(user.location, null, 2)}
                    </Col>
                  </Row>
                  <Row className="mt-10">
                    <Col span={6}>
                      <b>Profile:</b>
                    </Col>
                    <Col span={18}>{JSON.stringify(user.profile, null, 2)}</Col>
                  </Row>
                  <Row className="mt-10">
                    <Col span={6}>
                      <b>Employed at:</b>
                    </Col>
                    <Col span={18}>
                      {user?.employers?.map((e, i) => (
                        <div key={i}>
                          {e.current ? (
                            <Link to={`/orgs/${e.id}`}>{e.name}</Link>
                          ) : (
                            <>
                              <Space>
                                {e.name}
                                <span
                                  className="link"
                                  onClick={() => {
                                    exitForm.setFieldsValue({
                                      interview: e?.meta?.terminated_meta || [],
                                    });
                                    setCurrentEmployer(e);
                                    setExitInterviewOpened(true);
                                  }}
                                >
                                  Update Exit Interview
                                </span>
                              </Space>
                            </>
                          )}
                        </div>
                      ))}
                    </Col>
                  </Row>
                  <Row className="mt-10">
                    <Col span={6}>
                      <b>Personas:</b>
                    </Col>
                    <Col span={18}>
                      {user.personas.map((t, i) => (
                        <Tag color="green" className="mb-5" key={i}>
                          {t}
                        </Tag>
                      ))}
                    </Col>
                  </Row>
                  <Row className="mt-10">
                    <Col span={6}>
                      <b>Locale:</b>
                    </Col>
                    <Col span={18}>{user?.locale}</Col>
                  </Row>
                  <Row className="mt-10">
                    <Col span={6}>
                      <b>Terms Agreed:</b>
                    </Col>
                    <Col span={18}>
                      {user?.terms_agreed ? "Yes - " : "No"}{" "}
                      {user?.terms_conversation_id ? (
                        <Link
                          to={`/conversations/${user.terms_conversation_id}`}
                        >
                          {user.terms_conversation_id}
                        </Link>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-10">
                    <Col span={6}>
                      <b>Created:</b>
                    </Col>
                    <Col span={18}>
                      {dayjs(user?.created_at).format("YYYY-MM-DD")}
                    </Col>
                  </Row>
                  {user.nodes.length > 0 ||
                    (user.orgs.length > 0 && (
                      <Row className="mt-10">
                        <Col span={6}>
                          <b>Admin of:</b>
                        </Col>
                        <Col span={18}>
                          {user.nodes.map((n) => (
                            <div className="mb-5" key={n.id}>
                              <Link to={`/nodes/${n.id}`}>{n.name}</Link>
                            </div>
                          ))}
                          {user.orgs.map((o) => (
                            <div className="mb-5" key={o.id}>
                              <Link to={`/orgs/${o.id}`}>{o.name}</Link>
                            </div>
                          ))}
                        </Col>
                      </Row>
                    ))}
                  <Row className="mt-10">
                    <Col span={6}>
                      <b>Casey ends on:</b>
                    </Col>
                    <Col span={18}>
                      <Space>
                        {user?.casey_access_end_time
                          ? `${dayjs(user.casey_access_end_time).format(
                              "YYYY-MM-DD"
                            )} (PST)`
                          : ""}
                        <span
                          className="link"
                          onClick={() => {
                            caseyEndForm.setFieldsValue({
                              casey_access_end_time: user.casey_access_end_time
                                ? dayjs(
                                    user.casey_access_end_time,
                                    "YYYY-MM-DD"
                                  )
                                : null,
                            });
                            setCaseyEndOpened(true);
                          }}
                        >
                          Update End Date
                        </span>
                      </Space>
                    </Col>
                  </Row>
                  <Row className="mt-15">
                    <Popconfirm
                      title="Delete data"
                      description="Do you want to delete terms and your employement as well?"
                      onConfirm={() =>
                        dispatch(
                          resetProfile({
                            id: props.id,
                            data: { terms: true },
                          })
                        ).then((res) => {
                          if (res.type === "users/resetProfile/fulfilled") {
                            window.location.reload();
                          } else {
                            message.error(res.payload.errors[0]);
                          }
                        })
                      }
                      onCancel={() =>
                        dispatch(
                          resetProfile({
                            id: props.id,
                            data: { terms: false },
                          })
                        ).then((res) => {
                          if (res.type === "users/resetProfile/fulfilled") {
                            window.location.reload();
                          } else {
                            message.error(res.payload.errors[0]);
                          }
                        })
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button size="small" danger>
                        Reset Profile
                      </Button>
                    </Popconfirm>
                  </Row>
                </Col>
                {latestConversation &&
                  (latestConversation?.is_summary ? (
                    latestConversation.summary?.length > 0 && (
                      <Col span="10">
                        <div className="fw-600 mb-10">Conversation Summary</div>
                        <Paragraph
                          ellipsis={{
                            rows: 5,
                            expandable: true,
                            symbol: "more",
                          }}
                        >
                          {latestConversation.summary}
                        </Paragraph>
                        <Popconfirm
                          title="Reset summary"
                          description="Are you sure you want to reset summary? This action is not reversible."
                          onConfirm={() =>
                            dispatch(
                              resetSummary({
                                id: props.id,
                              })
                            ).then((res) => {
                              if (res.type === "users/resetSummary/rejected") {
                                message.error(res.payload.errors[0]);
                              } else {
                                window.location.reload();
                              }
                            })
                          }
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button size="small" danger>
                            Reset Summary
                          </Button>
                        </Popconfirm>
                      </Col>
                    )
                  ) : (
                    <Col span="10">
                      <div className="fw-600 mb-10">Conversation Summary</div>
                      <Paragraph>
                        Latest conversation is still ongoing. Summary not
                        generated yet.
                      </Paragraph>
                      <div className="mt-5">
                        <Popconfirm
                          title="Force summary generation"
                          description="Are you sure you want to force summary generation and close the conversation? This action is not reversible."
                          onConfirm={() =>
                            dispatch(
                              forceSummary({
                                id: props.id,
                              })
                            ).then((res) => {
                              if (res.type === "users/forceSummary/rejected") {
                                message.error(res.payload.errors[0]);
                              } else {
                                setTimeout(() => {
                                  window.location.reload();
                                }, 5000);
                              }
                            })
                          }
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button size="small" danger>
                            Force Summary Generation
                          </Button>
                        </Popconfirm>
                      </div>
                      <div className="mt-20">
                        <Popconfirm
                          title="Delete converation"
                          description="Are you sure you want to delete your latest conversation? This action is not reversible."
                          onConfirm={() =>
                            dispatch(
                              deleteConversation({
                                conversationId: latestConversation.id,
                              })
                            ).then((res) => {
                              if (
                                res.type === "conversations/delete/rejected"
                              ) {
                                message.error(
                                  "Could not delete conversation. Contact admin."
                                );
                              } else {
                                window.location.reload();
                              }
                            })
                          }
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button size="small" danger>
                            Delete Latest Conversation
                          </Button>
                        </Popconfirm>
                      </div>
                      {lastSummary?.summary?.length && (
                        <>
                          <div className="fw-600 mt-20 mb-10">
                            Last Available Summary ({lastSummary.id})
                          </div>
                          <Paragraph
                            ellipsis={{
                              rows: 5,
                              expandable: true,
                              symbol: "more",
                            }}
                          >
                            {lastSummary.summary}
                          </Paragraph>
                        </>
                      )}
                    </Col>
                  ))}
              </Row>
            </Spin>
          </div>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: "Conversations",
                children: (
                  <div className="container bg-white b-shadow">
                    <Conversations userId={user?.id} filters={{}} />
                  </div>
                ),
              },
              {
                key: "2",
                label: "Personal Data Sources",
                children: (
                  <div className="container bg-white b-shadow">
                    <Data userId={user?.id} />
                  </div>
                ),
              },
            ]}
          />
        </div>
      )}

      <Modal
        open={exitInterviewOpened}
        title={`Exit interview for ${currentEmployer?.name} - Terminated ${currentEmployer?.meta?.terminated}`}
        onCancel={() => {
          closeExitInterviewModal();
          exitForm.resetFields();
        }}
        footer={null}
        width={750}
        destroyOnClose={true}
      >
        <Form className="mt-30" form={exitForm} onFinish={updateExitInterview}>
          <Form.List name="interview">
            {(fields, { add, remove }, { errors }) => (
              <>
                <div className="ta-l">
                  <Space align="baseline">
                    <Button onClick={() => add()} className="mb-30">
                      Add Cause & Reason
                    </Button>
                    <div className="c-subtext fst-italic">
                      (ordered by priority)
                    </div>
                  </Space>
                </div>
                {fields.map((field, index) => (
                  <Row gutter={16} align="top" key={index}>
                    <Col span={1} className="ta-l">
                      <Form.Item>{index + 1}.</Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item
                        name={[index, "cause"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input value",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Cause"
                          options={[
                            {
                              value: "Low Pay",
                              label: "Low Pay",
                            },
                            {
                              value: "Bad Manager",
                              label: "Bad Manager",
                            },
                            {
                              value: "Health Emergency",
                              label: "Health Emergency",
                            },
                            {
                              value: "Lack of Career Growth",
                              label: "Lack of Career Growth",
                            },
                            {
                              value: "Other",
                              label: "Other",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={14}>
                      <Form.Item
                        name={[index, "reason"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input value",
                          },
                        ]}
                      >
                        <Input.TextArea autoSize placeholder="Reason" />
                      </Form.Item>
                    </Col>
                    <Col span={2} className="ta-r">
                      <Form.Item>
                        <Button
                          danger
                          type="link"
                          onClick={() => remove(field.name)}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
          <Form.Item className="ta-r mt-10">
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={caseyEndOpened}
        title="Update Casey End Date"
        onCancel={() => {
          caseyEndForm.resetFields();
          setCaseyEndOpened(false);
        }}
        footer={null}
        destroyOnClose={true}
      >
        <Form form={caseyEndForm} onFinish={update} className="mt-30">
          <Form.Item
            name="casey_access_end_time"
            label="Casey End Date (in PST)"
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item className="ta-r mt-10">
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
