import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { personaTags } from "../../constants";
import { listOrgs, orgsSelector } from "../orgs/orgsSlice";
import { searchServiceIds } from "../services/servicesSlice";
import {
  announcementsSelector,
  createAnnouncement,
  getAnnouncements,
} from "./announcementsSlice";

const PAGE_SIZE = 25;
const { TextArea } = Input;

export function Announcements() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    data: announcements,
    loading,
    errors,
    totalPages,
    totalCount,
  } = useSelector(announcementsSelector);
  const [pageNum, setPageNum] = useState(1);
  const { orgsList } = useSelector(orgsSelector);
  const [serviceIds, setServiceIds] = useState([]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    dispatch(getAnnouncements({ page: pageNum, per: PAGE_SIZE }));
    dispatch(listOrgs());
  }, []);

  const onPageChange = (page) => {
    setPageNum(page);
    dispatch(getAnnouncements({ page, per: PAGE_SIZE }));
  };

  const searchService = (val) => {
    setServiceIds([]);
    dispatch(
      searchServiceIds({
        query: val,
        page: 1,
        per: 10,
        filters: {},
      })
    ).then((res) => {
      if (res.type === "services/searchIds/fulfilled") {
        setServiceIds(res.payload.results);
      } else {
        message.error("Error retrieving service ids");
      }
    });
  };

  const handleSend = (values) => {
    let scope = values.scope;
    Modal.confirm({
      title: "Confirm",
      content: (
        <>
          <div>Are you sure you want to send this SMS?</div>
          <div className="fw-600 mt-10">{values.message}</div>
          <div className="fw-400">{values?.service_ids?.join(", ") || ""}</div>
          <div className="fs-2 c-divider mt-5 mb-5">TO</div>
          {!scope.org_ids?.length &&
            !scope.user_ids?.length &&
            !scope.personas?.length && <div className="fw-500">Everyone</div>}
          {scope.org_ids?.length > 0 && (
            <div>{`Orgs: ${scope.org_ids
              .map((o) => orgsList.find((x) => x.id === o).name)
              .join(" (or) ")}`}</div>
          )}
          {scope.personas?.length > 0 && (
            <div>{`Personas: ${scope.personas.join(" (or) ")}`}</div>
          )}
          {scope.user_ids?.length > 0 && (
            <div>{`User IDs: ${scope.user_ids.join(", ")}`}</div>
          )}
          <div className="mt-20">
            <div>
              {`--->`}&nbsp;&nbsp;
              {values.add_chat ? "Add to Chat Flow" : "Don't add to Chat Flow"}
            </div>
          </div>
        </>
      ),
      okText: "Send",
      cancelText: "Cancel",
      onOk: () => {
        if (scope.user_ids) {
          scope.user_ids = scope.user_ids.map((x) => parseInt(x));
        }
        dispatch(
          createAnnouncement({
            message: values.message,
            add_chat: values.add_chat,
            service_ids: values.service_ids,
            scope,
          })
        ).then((res) => {
          if (res.type === "announcements/create/fulfilled") {
            form.resetFields();
            dispatch(getAnnouncements({ page: pageNum, per: PAGE_SIZE }));
          } else {
            message.error({
              content: "Failed to send SMS",
            });
          }
        });
      },
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Service IDs",
      dataIndex: "service_ids",
      key: "service_ids",
      render: (service_ids) => service_ids.join(", ") || "",
    },
    {
      title: "Sent to",
      dataIndex: "scope",
      key: "scope",
      render: (scope) => (
        <>
          {!scope?.org_ids?.length && !scope?.user_ids?.length && (
            <span className="fw-600">Everyone</span>
          )}
          {scope?.org_ids?.length > 0 && (
            <div>
              Employees of:{" "}
              <span className="fw-600">
                {scope.org_ids
                  .map((o) => orgsList.find((x) => x.id === o)?.name)
                  .join(", ")}
              </span>
            </div>
          )}
          {scope?.user_ids?.length > 0 && (
            <div>
              User IDs:{" "}
              <span className="fw-600">{scope.user_ids.join(", ")}</span>
            </div>
          )}
          {scope?.personas?.length > 0 && (
            <div>
              Personas:{" "}
              <span className="fw-600">{scope.personas.join(", ")}</span>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Add to Chat",
      dataIndex: "add_chat",
      key: "add_chat",
      render: (add_chat) => (add_chat ? "Yes" : "No"),
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => new Date(created_at).toLocaleString(),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (status === 2 ? "Sent" : "Processing"),
    },
  ];

  return (
    <>
      <div className="container">
        <div className="mb-15">
          <Row justify="space-between" wrap>
            <Col>
              <span className="fs-5 fw-300">Announcements</span>
            </Col>
          </Row>
        </div>
        <div className="container bg-white b-shadow">
          <Form
            form={form}
            disabled={loading}
            requiredMark={false}
            initialValues={{
              scope: { org_ids: [], user_ids: [] },
              add_chat: true,
            }}
            onFinish={handleSend}
            onFieldsChange={(changed) => {
              if (changed.length > 0 && changed[0].name[0] === "scope") {
                switch (changed[0].name[1]) {
                  case "org_ids":
                  case "personas":
                    form.setFieldsValue({ scope: { user_ids: [] } });
                    break;
                  case "user_ids":
                    form.setFieldsValue({
                      scope: { org_ids: [], personas: [] },
                    });
                    break;
                  default:
                    break;
                }
              }
            }}
          >
            <Row gutter={24} align="middle">
              <Col span={20}>
                <div>
                  <Form.Item
                    name="message"
                    rules={[{ required: true, message: "Please input value" }]}
                  >
                    <TextArea
                      autoSize={true}
                      placeholder="Type message to send. One SMS segment is 160 characters. Cost is per segment sent per number."
                      showCount={true}
                      maxLength={320}
                    />
                  </Form.Item>
                </div>
                <div className="mt-30">
                  <Form.Item name="service_ids">
                    <Select
                      placeholder="Select Service IDs for the services you mentioned in your message"
                      allowClear
                      showSearch
                      mode="multiple"
                      options={serviceIds.map((t) => ({
                        label: `${t.id} - ${t.program}`,
                        value: t.id,
                      }))}
                      onSearch={searchService}
                      onDropdownVisibleChange={() => setServiceIds([])}
                    ></Select>
                  </Form.Item>
                </div>
                <Divider>
                  <span className="c-divider">TO</span>
                </Divider>
                <div>
                  <Row gutter={16} align="middle">
                    <Col span={2}>
                      <div className="c-subtext fw-500 ta-c">Everyone</div>
                    </Col>
                    <Col span={1}>
                      <div className="fs-1 fw-300 c-subtext ta-c">(OR)</div>
                    </Col>
                    <Col span={5}>
                      <Form.Item name={["scope", "org_ids"]}>
                        <Select
                          allowClear
                          showSearch
                          mode="multiple"
                          placeholder="Orgs"
                          options={orgsList.map((o) => ({
                            label: o.name,
                            value: o.id,
                          }))}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item name={["scope", "personas"]}>
                        <Select
                          allowClear
                          showSearch
                          mode="multiple"
                          placeholder="Personas"
                          options={personaTags
                            .filter((o) => o !== "all-users")
                            .map((o) => ({
                              label: o,
                              value: o,
                            }))}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <div className="fs-1 fw-300 c-subtext ta-c">(OR)</div>
                    </Col>
                    <Col span={5}>
                      <Form.Item name={["scope", "user_ids"]}>
                        <Select
                          mode="tags"
                          allowClear
                          placeholder="User IDs"
                          tokenSeparators={[","]}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <div className="c-subtext ta-c">{`-->`}</div>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="add_chat">
                        <Select
                          options={[
                            {
                              value: true,
                              label: "Add to Chat",
                            },
                            {
                              value: false,
                              label: "Don't add to Chat",
                            },
                          ]}
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={4} className="ta-r">
                <Button type="primary" htmlType="submit">
                  Send SMS
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="container bg-white b-shadow">
          <Table
            loading={loading}
            bordered
            size="small"
            columns={columns}
            dataSource={announcements.map((l, i) => {
              return { ...l, ...{ key: i } };
            })}
            pagination={{
              position: ["bottomRight"],
              onChange: onPageChange,
              pageSize: PAGE_SIZE,
              total: totalPages * PAGE_SIZE,
              showSizeChanger: false,
              current: pageNum,
              showTotal: () => (
                <span className="fs-2 c-subtext">{`${totalCount} ${
                  totalCount === 1 ? "announcement" : "announcements"
                }`}</span>
              ),
            }}
          />
        </div>
      </div>
    </>
  );
}
