import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useCSVReader } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  downloadEmployees,
  employeesSelector,
  getEmployees,
} from "./employeesSlice";
import { EmployeeUpdate } from "./EmployeeUpdate";

const PAGE_SIZE = 50;

export function Employees(props) {
  const dispatch = useDispatch();
  const { CSVReader } = useCSVReader();
  const {
    employees,
    loading,
    errors,
    totalPages,
    totalCount,
    currentCount,
    registeredCount,
    terminatedCount,
  } = useSelector(employeesSelector);
  const [uploadOpened, setUploadOpened] = useState(false);
  const [uploadData, setUploadData] = useState([]);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    if (props.id) {
      dispatch(
        getEmployees({
          id: props.id,
          page: pageNum,
          per: PAGE_SIZE,
        })
      );
    }
  }, [props.id, dispatch]);

  const onPageChange = (page) => {
    setPageNum(page);
    dispatch(
      getEmployees({
        id: props.id,
        page: page,
        per: PAGE_SIZE,
      })
    );
  };

  const downloadCsv = () => {
    dispatch(
      downloadEmployees({
        id: props.id,
        format: "csv",
      })
    ).then((res) => {
      if (res.type === "downloadEmployees/fulfilled") {
        const url = window.URL.createObjectURL(new Blob([res.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `employees - ${new Date().toLocaleString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        message.error("Failed to download employees data");
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        title: "User ID",
        dataIndex: "user_id",
        key: "user_id",
        render: (id) => {
          return id ? <Link to={`/users/${id}`}>{id}</Link> : "Not Registered";
        },
      },
      {
        title: "Member ID",
        dataIndex: ["meta", "member_id"],
        key: "member_id",
      },
      {
        title: "Store ID",
        dataIndex: ["meta", "store_id"],
        key: "store_id",
      },
      {
        title: "Store",
        dataIndex: ["meta", "store"],
        key: "store",
      },
      {
        title: "Joined",
        dataIndex: ["meta", "joined"],
        key: "joined",
      },
      {
        title: "Terminated",
        dataIndex: ["meta", "terminated"],
        key: "terminated",
        render: (t) => t && <div style={{ color: "#dc4446" }}>{t}</div>,
      },
    ],
    []
  );

  return (
    <>
      <div>
        <Row align="middle">
          <Col span={16}>
            [Pilot] -- In Scope: <span className="fw-600">{currentCount}</span>,
            Registered: <span className="fw-600">{registeredCount}</span>,
            Terminated: <span className="fw-600">{terminatedCount}</span>
          </Col>
          <Col span={4} className="ta-r">
            <Popconfirm
              title="Are you sure you want to export all employee data in the pilot?"
              onConfirm={downloadCsv}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DownloadOutlined />}>Export as CSV</Button>
            </Popconfirm>
          </Col>
          <Col span={4} className="ta-r">
            <Tooltip
              title="CSV file with headers - employee_id, joined_date, store_id, store, in_pilot"
              color="#4867b1"
            >
              {props.id === 167 ? (
                <div className="c-subtext">Upload file in main org</div>
              ) : (
                <div>
                  <CSVReader
                    onUploadAccepted={(results) => {
                      setUploadData(results.data);
                      setUploadOpened(true);
                    }}
                  >
                    {({ getRootProps }) => {
                      return (
                        <Button icon={<PlusOutlined />} {...getRootProps()}>
                          Employee Update
                        </Button>
                      );
                    }}
                  </CSVReader>
                </div>
              )}
            </Tooltip>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Table
            size="small"
            columns={columns}
            dataSource={employees.map((l, i) => {
              return { ...l, ...{ key: i } };
            })}
            className="mt-20 mb-20"
            pagination={{
              position: ["bottomRight"],
              onChange: onPageChange,
              pageSize: PAGE_SIZE,
              total: totalPages * PAGE_SIZE,
              showSizeChanger: false,
              current: pageNum,
              showTotal: () => (
                <span className="fs-2 c-subtext">{`${totalCount} ${
                  totalCount === 1 ? "employee in pilot" : "employees in pilot"
                }`}</span>
              ),
            }}
          />
        </Spin>
      </div>

      <Modal
        title="Employee Upload"
        open={uploadOpened}
        footer={null}
        destroyOnClose={true}
        closable={false}
        maskClosable={false}
        width={1000}
        style={{
          top: 32,
        }}
      >
        <div className="pt-10">
          <EmployeeUpdate
            id={props.id}
            data={uploadData.length > 0 ? uploadData.slice(1) : []}
            onSuccess={() => {
              setUploadOpened(false);
              setUploadData([]);
              dispatch(
                getEmployees({
                  id: props.id,
                  page: pageNum,
                  per: PAGE_SIZE,
                })
              );
            }}
            onCancel={() => {
              setUploadOpened(false);
              setUploadData([]);
            }}
          />
        </div>
      </Modal>
    </>
  );
}
