import { EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Descriptions,
  message,
  Row,
  Spin,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrgEditModal } from "./OrgEditModal";
import { getOrgDetails, orgsSelector } from "./orgsSlice";
import { Admins } from "./tabs/admins/Admins";
import { Employees } from "./tabs/employees/Employees";

const { TabPane } = Tabs;

export function OrgDetail(props) {
  const { loading, errors, orgsDetails } = useSelector(orgsSelector);
  const dispatch = useDispatch();
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    dispatch(
      getOrgDetails({
        id: props.id,
      })
    );
  }, [props, dispatch]);

  return (
    <>
      <div className="container">
        <div className="mb-25 bg-white b-shadow container pb-20">
          <Spin spinning={loading} style={{ padding: 20 }}>
            <Row gutter="24" align="top" wrap>
              <Col span="4" className="ta-c">
                <Avatar src={orgsDetails?.avatar} size={140} />
              </Col>
              <Col span="16">
                <div className="fs-6 mb-10">{orgsDetails?.name}</div>
                {/* <Descriptions size="small">
                  <Descriptions.Item label="Rate Limited">
                    {orgsDetails?.rate === -1 ? "No" : "Yes"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Rate">
                    {orgsDetails?.rate === -1
                      ? "Unlimited API calls"
                      : `${orgsDetails?.rate} calls / month`}
                  </Descriptions.Item>
                </Descriptions> */}
                <Descriptions size="small">
                  <Descriptions.Item label="Tech Contact">
                    {orgsDetails?.tech?.name} ({orgsDetails?.tech?.email})
                  </Descriptions.Item>
                  <Descriptions.Item label="Billing Contact">
                    {orgsDetails?.billing?.name} ({orgsDetails?.billing?.email})
                  </Descriptions.Item>
                </Descriptions>
                {/* <Descriptions size="small">
                  <Descriptions.Item label="Security Restrictions">
                    {orgsDetails?.restrict ? "Yes" : "No"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Restricted to">
                    {orgsDetails?.restrictions?.ip &&
                      orgsDetails?.restrictions?.ip.length > 0 && (
                        <>
                          {orgsDetails?.restrictions?.ip.join(", ")}
                          <br />
                        </>
                      )}
                    {orgsDetails?.restrictions?.domains &&
                      orgsDetails?.restrictions?.domains.length > 0 && (
                        <>{orgsDetails?.restrictions?.domains.join(", ")}</>
                      )}
                  </Descriptions.Item>
                </Descriptions> */}
              </Col>
              <Col span="4" className="ta-r">
                <Button
                  type="link"
                  size="large"
                  icon={<EditOutlined />}
                  onClick={() => setOpenEdit(true)}
                ></Button>
              </Col>
            </Row>
          </Spin>
        </div>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Employees" key="1">
            <div className="container bg-white b-shadow">
              <Employees id={orgsDetails?.id} />
            </div>
          </TabPane>
          <TabPane tab="Admins" key="5">
            <div className="container bg-white b-shadow">
              <Admins id={orgsDetails?.id} />
            </div>
          </TabPane>
          {/* <TabPane tab="Usage" key="1">
            <div className="container bg-white b-shadow">
              <Usage id={orgsDetails?.id} />
            </div>
          </TabPane>
          <TabPane tab="Activity" key="2">
            <div className="container bg-white b-shadow">
              <Activity id={orgsDetails?.id} />
            </div>
          </TabPane>
          <TabPane tab="Donations" key="3">
            <div className="container bg-white b-shadow">
              <Donations id={orgsDetails?.id} />
            </div>
          </TabPane>
          <TabPane tab="Customize" key="4">
            <div className="container bg-white b-shadow">
              <Personalize id={orgsDetails?.id} pType={orgsDetails?.p_type} />
            </div>
          </TabPane>
          <TabPane tab="Admins" key="5">
            <div className="container bg-white b-shadow">
              <Admins id={orgsDetails?.id} />
            </div>
          </TabPane> */}
        </Tabs>
      </div>
      <OrgEditModal
        open={openEdit}
        data={orgsDetails}
        create={false}
        onSuccess={() => {
          setOpenEdit(false);
          dispatch(
            getOrgDetails({
              id: props.id,
            })
          );
        }}
        onCancel={() => setOpenEdit(false)}
      />
    </>
  );
}
