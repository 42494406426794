import { message, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getServiceHistory } from "./servicesSlice";

const PAGE_SIZE = 10;

export function EditHistory(props) {
  const dispatch = useDispatch();
  const [logs, setLogs] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    if (props.id) {
      loadPage(pageNum);
    }
  }, [props.id]);

  const loadPage = (page) => {
    dispatch(getServiceHistory({ id: props.id, page, per: PAGE_SIZE })).then(
      (res) => {
        if (res.type === "services/getServiceHistory/fulfilled") {
          setLogs(res.payload.edit_logs);
          setTotalPages(res.payload.total_pages);
          setTotalCount(res.payload.total_count);
        } else {
          message.error("Failed to load history");
        }
      }
    );
  };

  const onPageChange = (page) => {
    setPageNum(page);
    loadPage(page);
  };

  return (
    <Table
      bordered
      size="small"
      columns={[
        {
          title: "User",
          dataIndex: "user_id",
          key: "user_id",
          render: (id) => <Link to={`/users/${id}`}>{id}</Link>,
        },
        {
          title: "Operation",
          key: "operation",
          render: (record) => (
            <>
              {record?.body?.op === "create" ? (
                <Tag color="success">CREATE</Tag>
              ) : record?.body?.op === "update" ? (
                <Tag color="processing">UPDATE</Tag>
              ) : (
                <Tag color="error">DELETE</Tag>
              )}
            </>
          ),
        },
        {
          title: "Data",
          key: "data",
          render: (record) => <>{`${JSON.stringify(record?.body?.data)}`}</>,
        },
        {
          title: "Updated at",
          dataIndex: "updated_at",
          key: "updated_at",
          render: (updated_at) => new Date(updated_at).toLocaleString(),
        },
      ]}
      dataSource={logs.map((l, i) => {
        return { ...l, ...{ key: i } };
      })}
      pagination={{
        position: ["bottomRight"],
        onChange: onPageChange,
        pageSize: PAGE_SIZE,
        total: totalPages * PAGE_SIZE,
        showSizeChanger: false,
        current: pageNum,
        showTotal: () => (
          <span className="fs-2 c-subtext">{`${totalCount} ${
            totalCount === 1 ? "log" : "logs"
          }`}</span>
        ),
      }}
    />
  );
}
