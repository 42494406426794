import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Input, Row, Space, Table, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Metric } from "../../components/Metric/Metric";
import { authSelector } from "../auth/authSlice";
import { metricsSelector, refreshMetrics } from "../nav/navSlice";
import { OrgEditModal } from "./OrgEditModal";
import { getOrgs, orgsSelector } from "./orgsSlice";

const { Search } = Input;
const PAGE_SIZE = 50;

export function Orgs() {
  const { loading, errors, orgs, totalPages, totalCount } =
    useSelector(orgsSelector);
  const { data: metrics, loading: metricsLoading } =
    useSelector(metricsSelector);
  const { currentUser } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [pageNum, setPageNum] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    dispatch(
      refreshMetrics({
        filter: "orgs",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getOrgs({
        page: 1,
        search: "",
      })
    );
  }, [currentUser, dispatch]);

  const search = (value) => {
    setSearchString(value);
    setPageNum(1);
    dispatch(
      getOrgs({
        page: 1,
        search: value,
      })
    );
  };

  const onPageChange = (page) => {
    setPageNum(page);
    dispatch(
      getOrgs({
        page: page,
        search: searchString,
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (text, record) => (
          <div size="middle">
            <div>{record.id}</div>
          </div>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Space size="middle">
            <Link to={`/orgs/${record.id}`}>
              <Avatar src={record?.avatar} size={18} />
              &nbsp;&nbsp;{record?.name}
            </Link>
          </Space>
        ),
      },
      {
        title: "Org Employees",
        dataIndex: "reco_c",
        key: "reco_c",
        render: (text, record) => (
          <Space size="middle">
            <div>{record?.employee_c}</div>
          </Space>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="container">
        <div className="mb-15">
          <Row justify="space-between" wrap>
            <Col>
              <span className="fs-5 fw-300">Organizations</span>
            </Col>
            <Col className="ta-r">
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={(e) => setOpenEdit(true)}
              >
                New
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mb-30">
          <Row gutter={24}>
            <Col span={6}>
              <Metric
                title="Total Orgs"
                current={metrics["total_c"]}
                loading={metricsLoading}
              />
            </Col>
            <Col span={6}>
              <Metric
                title="API Calls This Week (PST)"
                current={metrics["api_this_week_c"]}
                loading={metricsLoading}
              />
            </Col>
            <Col span={6}>
              <Metric
                title="Change From Calls Last Week"
                current={metrics["api_this_week_c"]}
                old={metrics["api_last_week_c"]}
                loading={metricsLoading}
              />
            </Col>
          </Row>
        </div>
        <div className="container bg-white b-shadow">
          <Row className="mb-20">
            <Col span={24}>
              <Search
                onSearch={search}
                size="large"
                enterButton={
                  <Button type="primary" ghost icon={<SearchOutlined />} />
                }
                placeholder="Search name..."
              />
            </Col>
          </Row>
          <div className="mt-30">
            <Table
              loading={loading}
              bordered
              size="small"
              columns={columns}
              dataSource={orgs.map((l, i) => {
                return { ...l, ...{ key: i } };
              })}
              pagination={{
                position: ["bottomRight"],
                onChange: onPageChange,
                pageSize: PAGE_SIZE,
                total: totalPages * PAGE_SIZE,
                showSizeChanger: false,
                current: pageNum,
                showTotal: (total) => (
                  <span className="fs-2 c-subtext">{`${totalCount} ${
                    totalCount === 1 ? "org" : "orgs"
                  }`}</span>
                ),
              }}
            ></Table>
          </div>
        </div>
      </div>
      <OrgEditModal
        open={openEdit}
        create={true}
        onSuccess={() => {
          setOpenEdit(false);
          dispatch(
            getOrgs({
              page: 1,
              search: "",
            })
          );
        }}
        onCancel={() => setOpenEdit(false)}
      />
    </>
  );
}
