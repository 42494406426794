import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Space, Table, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Metric } from "../../components/Metric/Metric";
import { Logo } from "../../components/logo/Logo";
import { metricsSelector, refreshMetrics } from "../nav/navSlice";
import { getUsers, usersSelector } from "./usersSlice";

const PAGE_SIZE = 50;

export function Users() {
  const {
    data: users,
    loading,
    errors,
    totalPages,
    totalCount,
  } = useSelector(usersSelector);
  const { data: metrics, loading: metricsLoading } =
    useSelector(metricsSelector);
  const [pageNum, setPageNum] = useState(1);
  const [searchString, setSearchString] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    dispatch(
      refreshMetrics({
        filter: "users",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getUsers({
        page: 1,
        search: "",
      })
    );
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (text, record) => (
          <div size="middle">
            <div>{record.id}</div>
          </div>
        ),
      },
      {
        title: "Name",
        dataIndex: "f_name",
        key: "f_name",
        render: (name, record) => (
          <>
            <Link to={`/users/${record?.id}`}>
              <Space size={8}>
                <Logo type="user" data={{ name: name, src: record?.avatar }} />
                {name}
              </Space>
            </Link>
          </>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (text, record) => (
          <div size="middle">
            <div>{record.email}</div>
          </div>
        ),
      },
      {
        title: "Admin of",
        dataIndex: "admin",
        key: "admin",
        render: (_, record) => (
          <>
            {record.nodes.length > 0 && (
              <>
                <div className="fs-1 fw-700">Nodes</div>
                <div size="middle" className="mt-5">
                  {record.nodes.map((o) => (
                    <div key={o.id} className="mb-5">
                      <Link to={`/nodes/${o.id}`}>{o.name}</Link>
                    </div>
                  ))}
                </div>
              </>
            )}
            {record.nodes.length > 0 && record.orgs.length > 0 && (
              <div className="mt-15"></div>
            )}
            {record.orgs.length > 0 && (
              <>
                <div className="fs-1 fw-700">Orgs</div>
                <div size="middle" className="mt-5">
                  {record.orgs.map((o) => (
                    <div key={o.id} className="mb-5">
                      <Link to={`/orgs/${o.id}`}>{o.name}</Link>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        ),
      },
    ],
    []
  );

  const search = () => {
    setPageNum(1);
    dispatch(
      getUsers({
        page: 1,
        search: searchString,
      })
    );
  };

  const onPageChange = (page) => {
    setPageNum(page);
    dispatch(
      getUsers({
        page: page,
        search: searchString,
      })
    );
  };

  return (
    <>
      <div className="container">
        <div className="mb-15">
          <Row justify="space-between" wrap>
            <Col>
              <span className="fs-5 fw-300">Users</span>
            </Col>
          </Row>
        </div>
        <div className="mb-30">
          <Row gutter={24}>
            <Col span={6}>
              <Metric
                title="Total Users"
                current={metrics["total_c"]}
                loading={metricsLoading}
              />
            </Col>
            <Col span={6}>
              <Metric
                title="New This Week (PST)"
                current={metrics["this_week_c"]}
                loading={metricsLoading}
              />
            </Col>
            <Col span={6}>
              <Metric
                title="Change From Last Week"
                current={metrics["this_week_c"]}
                old={metrics["last_week_c"]}
                loading={metricsLoading}
              />
            </Col>
          </Row>
        </div>
        <div className="container bg-white b-shadow">
          <Row className="mb-20" gutter={12}>
            <Col span={21}>
              <Input
                placeholder="Search id, name, email..."
                onChange={(e) => setSearchString(e.target.value)}
              />
            </Col>
            <Col span={3}>
              <Button
                icon={<SearchOutlined />}
                className="w-100"
                onClick={search}
                loading={loading}
              >
                Search
              </Button>
            </Col>
          </Row>
          <div className="mt-30">
            <Table
              loading={loading}
              bordered
              size="small"
              columns={columns}
              dataSource={users.map((l, i) => {
                return { ...l, ...{ key: i } };
              })}
              pagination={{
                position: ["bottomRight"],
                onChange: onPageChange,
                pageSize: PAGE_SIZE,
                total: totalPages * PAGE_SIZE,
                showSizeChanger: false,
                current: pageNum,
                showTotal: (total) => (
                  <span className="fs-2 c-subtext">{`${totalCount} ${
                    totalCount === 1 ? "user" : "users"
                  }`}</span>
                ),
              }}
            ></Table>
          </div>
        </div>
      </div>
    </>
  );
}
